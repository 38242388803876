const cySelectors = {
  SUBMIT_BTN: 'SUBMIT_BTN',
  BACK_BTN: 'BACK_BTN',
  MENU_CATEGORIES: 'MENU_CATEGORIES',
  MENU_CATEGORY_ITEM: 'MENU_CATEGORY_ITEM',
  MENU_CATEGORY_ITEM__DIETARY: 'MENU_CATEGORY_ITEM__DIETARY',
  MENU_INFO_BANNER: 'MENU_INFO_BANNER',
  MENU_ITEMS_SEARCH: 'MENU_ITEMS_SEARCH',
  MENU_ITEMS_SEARCH_BTN: '[data-cy="MENU_ITEMS_SEARCH"] .menu-items-search__button',
  MENU_ITEMS_SEARCH_INPUT: '[data-cy="MENU_ITEMS_SEARCH"] input[type="search"]',
  MENU_ITEMS_SEARCH_NO_RESULTS: 'MENU_ITEMS_SEARCH_NO_RESULTS',
  MENU_ITEMS_FILTERS_NO_RESULTS: 'MENU_ITEMS_FILTERS_NO_RESULTS',
  MENU_ITEMS_KCALS: 'MENU_ITEMS_KCALS',
  MENU_ITEMS_DISCOUNT: 'MENU_ITEMS_DISCOUNT',
  MENU_ITEMS_MODAL: 'MENU_ITEMS_MODAL',
  MENU_ITEMS_MODAL_TOTAL: 'MENU_ITEMS_MODAL_TOTAL',
  MENU_ITEMS_MODAL_TOTAL_DISCOUNTED: 'MENU_ITEMS_MODAL_TOTAL_DISCOUNTED',
  MENU_ITEMS_MODAL__DIETARY_PREFERENCES: 'MENU_ITEMS_MODAL__DIETARY_PREFERENCES',
  MENU_ITEMS_MODAL__ALLERGENS: 'MENU_ITEMS_MODAL__ALLERGENS',
  MENU_ITEMS_MODAL_FOOTER: 'MENU_ITEMS_MODAL_FOOTER',
  MENU_ITEMS_PRICE: 'MENU_ITEMS_PRICE',
  MENU_NAVIGATION_FILTER_BTN: 'MENU_NAVIGATION_FILTER_BTN',
  MENU_MODAL_FILTERS_PREFERENCES: 'MENU_MODAL_FILTERS_PREFERENCES',
  MENU_MODAL_FILTERS_ALLERGENS: 'MENU_MODAL_FILTERS_ALLERGENS',
  MENU_MODAL_FILTERS_SUBMIT: 'MENU_MODAL_FILTERS_SUBMIT',
  MENU_MODAL_FILTERS_CLEAR: 'MENU_MODAL_FILTERS_CLEAR',
  MENU_MODAL_FILTERS_CLOSE_BTN: 'MENU_MODAL_FILTERS_CLOSE_BTN',
  MENU_MODAL_FILTERS_INPUT: 'MENU_MODAL_FILTERS_INPUT',
  MENU_ITEMS_MODAL_NOTES: 'MENU_ITEMS_MODAL_NOTES',
  MENU_MODAL_PRIORITIES: 'MENU_MODAL_PRIORITIES',
  BANNER_VENUE_TAKEAWAY: 'BANNER_VENUE_TAKEAWAY',
  MODIFIERS_SIZES: 'MODIFIERS_SIZES',
  MODIFIERS_SIZE: 'MODIFIERS_SIZE',
  MODIFIERS_RADIOS: 'MODIFIERS_RADIOS',
  MODIFIERS_RADIO: 'MODIFIERS_RADIO',
  MODIFIERS_CHECKBOXES: 'MODIFIERS_CHECKBOXES',
  MODIFIERS_CHECKBOX: 'MODIFIERS_CHECKBOX',
  MODIFIERS_INCREASE_BTN: 'MODIFIERS_INCREASE_BTN',
  MODIFIERS_DECREASE_BTN: 'MODIFIERS_DECREASE_BTN',
  MODIFIERS_SUBMIT_BTN: 'MODIFIERS_SUBMIT_BTN',
  CART_ITEM: 'CART_ITEM',
  CART_ITEM_ROW: 'CART_ITEM_ROW',
  CART_ITEM_NOTES: 'CART_ITEM_NOTES',
  CART_GROUP_ITEM: 'CART_GROUP_ITEM',
  CART_GROUP_ITEM_PRIORITY: 'CART_GROUP_ITEM_PRIORITY',
  CART_ITEM_COUNT: 'CART_ITEM_COUNT',
  CART_ITEM_VENDOR_NAME: 'CART_ITEM_VENDOR_NAME',
  CART_ITEM_INCREASE_BTN: 'CART_ITEM_INCREASE_BTN',
  CART_ITEM_DECREASE_BTN: 'CART_ITEM_DECREASE_BTN',
  CART_ITEM_PRICES_PRICE: 'CART_ITEM_PRICES_PRICE',
  CART_ITEM_PRICES_PRE_DISCOUNT_PRICE: 'CART_ITEM_PRICES_PRE_DISCOUNT_PRICE',
  CART_REMOVE_BTN: 'CART_REMOVE_BTN',
  REVIEW_FORM_NEW_NOTES: 'REVIEW_FORM_NEW_NOTES',
  DETAILS_FORM: 'DETAILS_FORM',
  DETAILS_FORM_TABLE: 'DETAILS_FORM_TABLE',
  DETAILS_FORM_NAME: 'DETAILS_FORM_NAME',
  DETAILS_FORM_EMAIL: 'DETAILS_FORM_EMAIL',
  DETAILS_FORM_PHONE: 'DETAILS_FORM_PHONE',
  CAMPAIGN_SLIDER_ITEM: 'CAMPAIGN_SLIDER_ITEM',
  CHECKOUT_TAKEAWAY_DAY: 'CHECKOUT_TAKEAWAY_DAY',
  CHECKOUT_TAKEAWAY_TIME: 'CHECKOUT_TAKEAWAY_TIME',
  CHECKOUT_SERVICE_CHARGE: 'CHECKOUT_SERVICE_CHARGE',
  CHECKOUT_ADDITIONAL_CHARGE: 'CHECKOUT_ADDITIONAL_CHARGE',
  CHECKOUT_MARKETING_OPTIN: 'CHECKOUT_MARKETING_OPTIN',
  CHECKOUT_CHECKOUTCOM_3DS_IFRAME: 'CHECKOUT_CHECKOUTCOM_3DS_IFRAME',
  CHECKOUT_STRIPE_3DS_IFRAME: 'iframe[src^="https://js.stripe.com/v3/authorize"]',
  CHECKOUT_STRIPE_3DS_IFRAME_FAIL_BTN: '#test-source-fail-3ds',
  CHECKOUT_STRIPE_3DS_IFRAME_SUCCESS_BTN: '#test-source-authorize-3ds',
  CHECKOUT_STRIPE: 'CHECKOUT_STRIPE_IFRAME',
  CHECKOUT_STRIPE_IFRAME: '[data-cy="CHECKOUT_STRIPE_IFRAME"] iframe',
  CHECKOUT_STRIPE_CARD_NUMBER: '[name="cardnumber"]',
  CHECKOUT_STRIPE_EXPIRY_DATE: '[name="exp-date"]',
  CHECKOUT_STRIPE_CVC: '[name="cvc"]',
  CHECKOUT_CKO_CARD_NUMBER_IFRAME: '#cardNumber',
  CHECKOUT_CKO_CARD_NUMBER: '#checkout-frames-card-number',
  CHECKOUT_CKO_EXPIRY_IFRAME: '#expiryDate',
  CHECKOUT_CKO_EXPIRY: '#checkout-frames-expiry-date',
  CHECKOUT_CKO_CVV_IFRAME: '#cvv',
  CHECKOUT_CKO_CVV: '#checkout-frames-cvv',
  CHECKOUT_REVEAL_CARD: 'CHECKOUT_REVEAL_CARD',
  CHECKOUT_PAIDUP_BUTTON: 'CHECKOUT_PAIDUP_BUTTON',
  CHECKOUT_PAIDUP_BANKS: 'CHECKOUT_PAIDUP_BANKS',
  CHECKOUT_PAIDUP_PROVIDER_BTN: 'CHECKOUT_PAIDUP_PROVIDER_BTN',
  CHECKOUT_FORM_ERROR: 'CHECKOUT_FORM_ERROR',
  CHECKOUT_PAID_UP_UPSELL: 'CHECKOUT_PAID_UP_UPSELL',
  CHECKOUT_TOTAL: 'CHECKOUT_TOTAL',
  CONFIRMATION_NAME: 'CONFIRMATION_NAME',
  CONFIRMATION_EMAIL: 'CONFIRMATION_EMAIL',
  CONFIRMATION_SUBTOTAL: 'CONFIRMATION_SUBTOTAL',
  CONFIRMATION_SERVICE_CHARGE: 'CONFIRMATION_SERVICE_CHARGE',
  CONFIRMATION_ADDITIONAL_CHARGE: 'CONFIRMATION_ADDITIONAL_CHARGE',
  CONFIRMATION_TOTAL: 'CONFIRMATION_TOTAL',
  CONFIRMATION_TABLE: 'CONFIRMATION_TABLE',
  CONFIRMATION_PAID_VIA: 'CONFIRMATION_PAID_VIA',
  CONFIRMATION_TAKEAWAY_TIME: 'CONFIRMATION_TAKEAWAY_TIME',
  CONFIRMATION_NOTES: 'CONFIRMATION_NOTES',
  CONFIRMATION_SYSTEM_NOTES: 'CONFIRMATION_SYSTEM_NOTES',
  CONFIRMATION_DETAILS_TOGGLE: 'CONFIRMATION_DETAILS_TOGGLE',
  COOKIE_CONSENT_MODAL: 'COOKIE_CONSENT_MODAL',
  PROMOTIONS_MODAL: 'PROMOTIONS_MODAL',
  OVERLAY_MODAL_BTN: 'OVERLAY_MODAL_BTN',
  FORM_FIELD_ERROR: 'FORM_FIELD_ERROR',
  FORM_FIELD_SUCCESS: 'FORM_FIELD_SUCCESS',
  FORM_FIELD_SELECT_ERROR: 'FORM_FIELD_SELECT_ERROR',
  FORM_FIELD_SUBMIT: 'FORM_FIELD_SUBMIT',
  CHECKOUTCODE_INPUT: 'CHECKOUTCODE_INPUT',
  CHECKOUTCODE_BTN: 'CHECKOUTCODE_BTN',
  CHECKOUTCODE_REMOVE_BTN: 'CHECKOUTCODE_REMOVE_BTN',
  VOUCHERS_AMOUNT_FIELD: 'VOUCHERS_AMOUNT_FIELD',
  VOUCHERS_EXPIRY_TOGGLE: 'VOUCHERS_EXPIRY_TOGGLE',
  VOUCHERS_EXPIRY_CHOOSERS: 'VOUCHERS_EXPIRY_CHOOSERS',
  VOUCHERS_EXPIRY_START: '#startDate',
  VOUCHERS_EXPIRY_END: '#endDate',
  VOUCHERS_CURRENT_BALANCE: 'VOUCHERS_CURRENT_BALANCE',
  VOUCHERS_BANNER: 'VOUCHERS_BANNER',
  FULLSCREEN_MODAL_OVERLAY: 'FULLSCREEN_MODAL_OVERLAY',
  FULLSCREEN_NOTIFICATION: 'FULLSCREEN_NOTIFICATION',
  FULLSCREEN_MODAL_CLOSE_BTN: 'FULLSCREEN_MODAL_CLOSE_BTN',
  LEAVE_REVIEW_CTA: 'LEAVE_REVIEW_CTA',
  VENDOR_ITEM: 'VENDOR_ITEM',
  VENDOR_ITEM_CLOSED: 'VENDOR_ITEM_CLOSED',
  COLLECTION_SWIPER_KNOB: 'COLLECTION_SWIPER_KNOB',
  MENU__PAGE__HEADER__DESKTOP: 'MENU__PAGE__HEADER__DESKTOP',
  HERO_BANNER__DESKTOP: 'HERO_BANNER__DESKTOP',
  ITEM_ALLERGENS: 'ITEM_ALLERGENS',
  OPENING_TIMES: 'OPENING_TIMES',
  OPENING_TIMES_MODAL: 'OPENING_TIMES_MODAL',
};

export default cySelectors;
